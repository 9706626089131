import axios from "axios";
import { createContext } from "react";
import { axiosETAGCache } from "axios-etag-cache";
import billingApi from "../../../core/billing/frontend/apis";
import bulkChartEvaluationApi from "../../../core/bulk_chart_evaluations/frontend/apis";
import businessInfoApi from "../../../shared/business_info/frontend/apis";
import conversationsApi from "../../../core/conversations/frontend/apis";
import customersApi from "../../../remindertap/customers/frontend/apis";
import outboundsApi from "../../../core/outbound/frontend/apis";
import knowledgeBaseApi from "../../../core/knowledge_base/frontend/apis";
import mybookrobotApi from "../../../mybookrobot/frontend/apis";
import fileUploadAPI from "../../../core/file_uploads/frontend/apis";
import insuranceAgentsApi from "../../../remindertap/insurance_agents/frontend/apis";
import networkingBotAPI from "../../../networkingbot/frontend/apis";
import onboardingApi from "../../../core/onboarding/frontend/apis";
import promptChartApi from "../../../core/prompt_chart/frontend/apis";
import salespersonAPI from "../../../salesperson/frontend/apis";
import schedulingApi from "../../../shared/scheduling/frontend/apis";
import syntheticUserApi from "../../../core/synthetic/frontend/apis";
import tenantApi from "../../../core/frontend/tenant_configuration/apis";
import translationApi from "../../../core/translation/frontend/apis";
import voiceApi from "../../../core/voice/frontend/apis";
import webApi from "../../../core/web/frontend/apis";
import wordFactoryApi from "../../../wordfactory/frontend/apis";
import imageUploadApi from "../../../core/image_uploads/frontend/apis";
import documentGeneratorApi from "../../../core/document_generator/frontend/apis";
import axiosRetry from "axios-retry";
import intakeBotApi from "../../../core/intake_bot/frontend/apis";

export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

export const api = {
  async home() {
    const response = await axiosWithETAGCache.get(`/`);
    return response.data;
  },
  async frontendConfiguration() {
    const response = await axiosWithETAGCache.get(`/frontend_configuration`);
    return response.data;
  },
  async isAdmin() {
    const response = await axiosWithETAGCache.get(`/is-admin`);
    return response.data;
  },
  ...billingApi,
  ...bulkChartEvaluationApi,
  ...businessInfoApi,
  ...conversationsApi,
  ...customersApi,
  ...outboundsApi,
  ...knowledgeBaseApi,
  ...mybookrobotApi,
  ...networkingBotAPI,
  ...fileUploadAPI,
  ...insuranceAgentsApi,
  ...onboardingApi,
  ...promptChartApi,
  ...salespersonAPI,
  ...schedulingApi,
  ...syntheticUserApi,
  ...tenantApi,
  ...translationApi,
  ...voiceApi,
  ...webApi,
  ...wordFactoryApi,
  ...imageUploadApi,
  ...documentGeneratorApi,
  ...intakeBotApi,
};

// Setup retries on API calls with backoff
axiosRetry(axios, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
});

// TODO: FIX ME
if (process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT) {
  axios.defaults.baseURL = process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT;
} else {
  // Just assume the API url is the same as the page the code is loaded in.
  axios.defaults.baseURL = `https://${window.location.hostname}/api/`;
}

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Auth0.logout();
      return Promise.reject(error);
    }

    if (process.env.REACT_APP_DEBUG === "true") {
      alert(error.toString());
    } else {
      // Force reload the page. maybe this will help.
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
