import React from "react";
import {useFrontendConfiguration} from "./FrontendConfigurationProvider";

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}


export const GoogleMapsProvider = ({children}) => {
    const frontendConfiguration = useFrontendConfiguration();
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${frontendConfiguration.frontend.google_maps_api_key}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    return <>
        {children}
    </>;
};

